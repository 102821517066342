@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
    box-shadow: inset 0 0 5px F2F2F2;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #6f7263;
    border-radius: 100vh;
    border: 3px solid #6f7263;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #6f7263;
  }
}

@font-face {
  font-family: 'Antarctican Headline';
  src: url('/fonts/antarcticanheadline-book-webfont.woff2') format('woff2');
  font-weight: 100 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antarctican Headline';
  src: url('/fonts/antarcticanheadline-medium-webfont.woff2') format('woff2');
  font-weight: 300 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antarctican Headline';
  src: url('/fonts/antarcticanheadline-semibold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antarctican Headline';
  src: url('/fonts/antarcticanheadline-bold-webfont.woff2') format('woff2');
  font-weight: 700 900;
  font-style: normal;
  font-display: swap;
}

/* input label flow style */
label.active > span.select-label {
  background-color: white;
}
@layer utilities {
  body::-webkit-scrollbar {
    @apply w-2;
  }

  body::-webkit-scrollbar-track {
    @apply bg-[#E2E8F0];
  }

  body::-webkit-scrollbar-thumb {
    @apply bg-leaf;
  }

  .filter-body::-webkit-scrollbar {
    @apply w-[6px] rounded-[3px];
  }

  .filter-body::-webkit-scrollbar-track {
    @apply bg-grey-100;
  }

  .filter-body::-webkit-scrollbar-thumb {
    @apply bg-grey-400;
  }

  .visible-snack-bar-main-content {
    @apply !mt-[112px] md:!mt-[144px] lg:!mt-[212px] xl:!mt-[169px];
  }

  .visible-snack-bar-header {
    @apply !top-[48px] md:!top-[44px] xl:!top-[56px];
  }
}

.star-ratings {
  @apply !flex;
}

input {
  --tw-ring-shadow: 0 0 #000 !important;
}
.slider_wrap > .slick-slider {
  @apply w-full max-w-[100vw] overflow-hidden;
}

.slider_wrap:before {
  @apply absolute left-0 top-0 z-[1] h-full w-[30px] content-[''];
  background: linear-gradient(90deg, rgba(246, 248, 238, 1) 50%, rgba(255, 255, 255, 0) 100%);
}
.slider_wrap:after {
  @apply absolute right-0 top-0 z-[1] h-full w-[30px] content-[''];
  background: linear-gradient(-90deg, rgba(246, 248, 238, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

blockquote::before {
  content: '\201C' !important;
}

.overflow-wrap-everywhere {
  overflow-wrap: anywhere;
}

button.gm-ui-hover-effect {
  @apply invisible;
}

.gm-style-iw {
  @apply !pl-0 !pt-0;
}

.gm-style-iw-tc {
  @apply invisible;
}

.product-card-footer > *:not(:last-child) {
  margin-right: 0;
  margin-left: 0;
}
.product-card-footer > :nth-last-child(2) {
  margin-right: auto;
  margin-left: 0;
}

video::-webkit-media-controls-panel {
  display: flex !important;
  opacity: 1 !important;
}

@keyframes smoothOpen {
  from {
    opacity: 0;
    transform: translateX(-390px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.openMenuAnimation {
  animation: smoothOpen 0.5s ease forwards;
}
